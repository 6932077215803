.btn{
  background: $green-400;
  color: $white;
  text-transform: uppercase;
  border-radius: 0;
  padding: 16px 48px;
  transition: all $animationTime ease-in-out;

  &:hover{
    opacity: 0.7;
    cursor: pointer;
  }
}