.about{
  padding: 45px 0;
  border-bottom: 1px solid $grey-200;

  &-wrapper{
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm){
      display: block;
    }

    img{
      float: left;
      margin-right: 30px;

      @include media-breakpoint-down(md){
        width: 250px;
        height: auto;
      }

      @include media-breakpoint-down(sm){
        width: 100%;
        float: none;
      }

      @include media-breakpoint-down(xs){
        margin-right: 0;
      }
    }

    p{
      font-size: 24px;

      @include media-breakpoint-down(sm){
        margin-top: 20px;
      }

      @include media-breakpoint-down(xs){
        font-size: 18px;
      }
    }
  }
}