header{
  nav {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
}


.slicknav_menu {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.slicknav_nav{
  li{
    a{
      text-transform: uppercase;
      font-weight: 700;
      font-size: 20px;
      margin: 10px 0;
    }
  }
}