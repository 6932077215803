.slider {
  background-size: cover;
  background-position: left;
  height: 500px;

  @include media-breakpoint-down(lg){
    height: 400px;
  }

  @include media-breakpoint-down(md){
    height: 300px;
  }

  @include media-breakpoint-down(sm){
    height: 240px;
    background-position-x: -50px;
  }

  .container{
    height: 100%;

    .row{
      height: 100%;
    }
  }

  &-rightText{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include media-breakpoint-down(xs){
      background: rgba(255, 255, 255, 0.65);
      justify-content: center;
    }

    > p {
      font-size: 36px;
      text-align: right;
      line-height: 50px;
      margin: 0;
      color: $black;

      @include media-breakpoint-down(md){
        font-size: 28px;
        line-height: 36px;
      }

      @include media-breakpoint-down(sm){
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
      }

      @include media-breakpoint-down(xs){
        text-align: center;
      }

      .author{
        font-style: italic;
        color: $grey-400;
      }
    }
  }
}