.folders {
  padding-top: 15px;
  padding-bottom: 30px;
  border-bottom: 1px solid $grey-200;

  &-folder {
    border: 1px solid $grey-200;
    margin-bottom: 30px;

    > a {
      display: block;
      height: 232px;
      overflow: hidden;

      @include media-breakpoint-down(lg){
        height: 190px;
      }

      @include media-breakpoint-down(md){
        height: 139px;
      }

      @include media-breakpoint-down(xs){
        height: 188px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    p {
      margin: 0;

      a {
        border-top: 1px solid $grey-200;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        color: $grey-500;
        text-align: center;
        height: 75px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(lg){
          font-size: 18px;
        }

        @include media-breakpoint-down(md){
          height: 90px;
        }
      }
    }

  }

}