.contact{
  padding: 45px 0;

  &-info{
    @include media-breakpoint-down(md){
      margin-bottom: 30px;
    }
    h2{
      text-transform: uppercase;
      font-size: 24px;
      color: $green-400;
      margin-bottom: 30px;

      @include media-breakpoint-down(md){
        text-align: center;
      }

      @include media-breakpoint-down(xs){
        font-size: 20px;
      }
    }

    p{
      position: relative;
      font-size: 22px;
      display: flex;
      align-items: center;

      @include media-breakpoint-down(md){
        justify-content: center;
      }

      @include media-breakpoint-down(xs){
        font-size: 20px;
      }

      > i {
        margin-right: 25px;
        font-size: 30px;
        color: $green-400;
      }

      a{
        color: $grey-400;
      }
    }
  }

  &-form{
    form{
      button{
        float: right;
      }
    }
  }
}