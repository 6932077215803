footer {
  padding: 45px 0;
  background: $grey-100;
  margin-top: -6px;

  .footerNav {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;

    @include media-breakpoint-down(md){
      justify-content: center;
    }

    @include media-breakpoint-down(xs){
      display: block;
      text-align: center;
    }

    ul {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      @include media-breakpoint-down(xs){
        display: block;
      }

      li {
        &:last-child {
          a {
            @include media-breakpoint-down(md) {
              margin-right: 0;
            }
          }
        }

        a {
          color: $grey-400;
          margin-right: 70px;
          text-transform: uppercase;

          @include media-breakpoint-down(sm) {
            margin-right: 50px;
          }

          @include media-breakpoint-down(xs){
            margin: 16px 0;
            display: block;
          }

          &:hover {
            color: $grey-400;
          }
        }

        .active {
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }
  }

  .footerText {
    p {
      margin-bottom: 6px;

      @include media-breakpoint-down(sm) {
        text-align: center;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .footerContact {
    @include media-breakpoint-down(sm) {
      margin-top: 15px;
    }

    p {
      margin-bottom: 6px;

      @include media-breakpoint-down(sm) {
        text-align: center;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}