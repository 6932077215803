#map {
  iframe{
    width: 100%;
    height: 500px;
    margin: 0;
    padding: 0;

    @include media-breakpoint-down(xs){
      height: 300px;
    }
  }
}