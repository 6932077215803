header{
  height: 160px;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(xs){
    height: 90px;
  }

  .logo{
    text-align: center;

    a{
      font-family: $secondaryFont;
      color: $white;
      font-size: 22px;

      @include media-breakpoint-down(xs){
        font-size: 20px;
      }
    }
  }

  nav{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include media-breakpoint-down(md){
      justify-content: center;
      margin-top: 30px;
    }

    ul{
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li{

        &:first-child{
          a{
            margin-left: 0;
          }
        }

        a, a:not([href]):not([tabindex]){
          color: $white;
          margin-left: 50px;
          text-transform: uppercase;

          &:hover, &:focus, &:active{
            color: $white;
          }
        }

        .active{
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }
  }
}