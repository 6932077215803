html{
  body{
    font-family: $primaryFont;
    background: $green-400;
    color: $grey-400;
    font-weight: 300;

    p{
      font-size: 18px;
      line-height: 32px;
    }

    a{
      transition: all $animationTime ease-in-out;

      &:hover{
        text-decoration: none;
        opacity: 0.7;
        cursor: pointer;
      }
    }

    ul{
      li{
        margin: 0;
        padding: 0;
      }
    }

    .sectionTitle{
      text-align: center;
      margin: 30px 0 60px;

      h1{
        text-transform: uppercase;
        font-size: 34px;
        color: $green-400;

        @include media-breakpoint-down(xs){
          font-size: 28px;
        }
      }
    }
  }
}